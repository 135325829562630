import { ProductAddonsSliceProps } from '@ui/slice-machine/slices/product-addons-slice/ProductAddonsSlice';
import dynamic from 'next/dynamic';

const ProductAddons = dynamic<ProductAddonsSliceProps>(() =>
  import(
    '@ui/slice-machine/slices/product-addons-slice/ProductAddonsSlice'
  ).then((val) => val.ProductAddonsSlice),
);

export default ProductAddons;
